@import	"_kit/normalise";

$body-background:#f2f2f2; // off-white
$body-color:#333; // Drak grey

$light-spot: #B9E88B;
$mid-spot: #81D135;
$dark-spot: #437414;
$man-nav-break-point: 1350px;

$tint:#64b35e;
$dark-tint:darken(#64b35e, 10%);
$darker-tint:darken(#64b35e, 20%);

html, body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; // Easy rem values
  height: 100%; // May muck-up stuff!
}

body {
  // background: #81D135;
  background: #333; // lighten(rgb(72,51,28), 15%);
  color: #777;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
  font-size: 1.6rem;
  height: 100%; // May muck-up stuff!
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.locked {
    overflow:hidden;
  }

}

textarea, input {outline:none;} // remove webkit glow

textarea, input {
  width: 100%;
  display: block;
  margin: 16px 0;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 1.2rem 1.2rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-appearance: none; // careful if we want to use radio button or check-box!

  &.error {
    border-color: tomato;
  }

  &:focus {
    border-color: #333;
  }
}

textarea {
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  resize: none;
}

* {
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

// Modern clearfix hack http://css-tricks.com/snippets/css/clear-fix/ for IE8+
.cf-group:after {
  content:"";
  display:table;
  clear:both;
}

hr {
  border: 0;
  height: 1px;
  background: white;
}

#message-box {
  display:none;
  background:green;
  z-index:9999;
  position:fixed;
  top:0;
  width:100%;
  padding:8px;
  color:white;
  text-align:center;
  -webkit-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.5);
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.5);

  .title {
    font-weight:700;
    display:block;
  }

  .content {
    font-size:14px;
  }

  &.no {
    background:tomato;
    color:white;
  }

  @media only screen and (min-width:800px) {
    padding:16px;

    .title {
      display:inline;
    }

  }

}

body.announcementBannerVisible {

  &.bannerAmber {
    .message-banner {
      .m-b-content {
        background: orange;
      }
      a {
        color: #333;
      }
      .faux-link {
        border-color: #333;
      }
    }
  }
  &.bannerGreen {
    .message-banner {
      .m-b-content {
        background: rgb(59, 116, 55);
      }
      a {
        color: white;
      }
      .faux-link {
        border-color: white;
      }
    }
  }

}

.message-banner {
  position: fixed;
  width: 100%;
  top: 79px;
  z-index: 10;
  display: block;

  @media only screen and (min-width:$man-nav-break-point) {
    z-index: 20;
  }

  .m-b-content {
    text-align: center;
    padding: 0.8rem;
    width: 100%;
    margin: 0 auto;
    background: orange;
    font-size: 1.3rem;
    line-height: 1.5em;
    // border: 1px solid darken(orange, 10%);

    @media only screen and (min-width:800px) {
      padding: 1.6rem 0.8rem;
    }
  }

  a {
    text-decoration: none;
    color: #333;
    display: block;
  }

  .faux-link {
    text-transform: uppercase;
    font-size: 1rem;
    border-bottom: 1px solid #333;
    display: inline-block;
  }
}

.wrapper-main {
  position: relative;
  width: 100%;
  min-height: 100%;

  .bg-img {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent url(#) no-repeat top center;
    background-size: cover;

    // @media only screen and (min-width:$man-nav-break-point) {
    //   position: absolute;
    //   display: block;
    //   background-attachment: fixed;
    // }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: transparentize($darker-tint, 0.6);
    }

  }

}

#work, #benefits {
  .wrapper-main .bg-img:after {
    background: transparentize($darker-tint, 0.5);
  }
}

#home .bg-img:after {
  background-color: transparentize($darker-tint, 0.7);
}

h1, h2, h3, h4 {
  font-weight: 300;
}

// Header Styles
.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  // background: darken(rgba(72,51,28, 0.7), 10%);
  background:rgba(255,255,255,0.75);
  height: 79px;
  border-top:5px solid rgb(59, 116, 55);
}

.logo-link {
  position:absolute;
  top:2.2rem;
  left:0.8rem;
  // width:200px;
  z-index:80;

  img {
    width:200px;
  }

  @media only screen and (min-width:460px) {
    top:1.1rem;
    left:1.6rem;

    img {
      width:100%;
    }
  }
}

.hamburger {
  // background: rgba(0,0,0, 0.2);
  display: block;
  width:43px;
  height:43px;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius:3px;
  text-indent: -50px;
  line-height: 43px;
  color: #333;
  text-decoration: none;
  overflow: hidden;

  @media only screen and (min-width:480px) {
    overflow:visible;
  }

  @media only screen and (min-width:$man-nav-break-point) {
    display: none;
  }

  span {
    position: absolute;
    top: 1.2rem;
    left: 0.8rem;
    display: block;
    width:27px;
    height:2px;
    background: $dark-tint;

    &:after, &:before {
      content:'';
      width:100%;
      height:2px;
      background: $dark-tint;
      position: absolute;
    }

      &:after {
        right:0;
        bottom:-8px;
      }

      &:before {
        right:0;
        bottom:-16px;
      }

  }


}

.main-nav {
  position:fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: rgba(0,0,0, 0.5);
  padding: 0.8rem;
  padding-top: 2.8rem;
  overflow-y: auto;
  -webkit-overflow-scrolling:touch;
  z-index: 15;
  display: block;
  -ms-transform: translate(250px,0); /* IE 9 */
  -webkit-transform: translate(250px,0); /* Safari */
  transform: translate(250px,0);
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;

  @media only screen and (min-width:$man-nav-break-point) {
    padding: 0;
    background: transparent;
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    height: auto;
    display: block;
    -ms-transform: translate(0,0); /* IE 9 */
    -webkit-transform: translate(0,0); /* Safari */
    transform: translate(0,0);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media only screen and (min-width:$man-nav-break-point) {
      float: right;
    }

    li {
      margin-bottom: 1px;

      @media only screen and (min-width:$man-nav-break-point) {
        margin: 0;
        float: left;
        margin-right: 1.6rem;
        padding-top: 2px;
        border-bottom: 2px solid transparent;
      }

      a {
        padding: 1.6rem 0.8rem;
        display: block;
        color: white;
        text-decoration: none;

        @media only screen and (min-width:$man-nav-break-point) {
          padding:0;
          background: transparent;
          line-height: 70px;
          display: inline;
          color: $dark-tint;
        }

          &:hover {
            opacity: 1;
            @media only screen and (min-width:$man-nav-break-point) {
              border-bottom: 2px solid $dark-tint;
            }
          }

        }

      &.active {

        a {
          @media only screen and (min-width:$man-nav-break-point) {
            color: $dark-tint;
          }
          border-bottom: 2px solid $darker-tint;
        }

      }

    }

  }

  @media only screen and (min-width:1120px) {
    font-size: 1.5rem;
  }

}

.button {
  border:0;
  border-width: 2px !important;
  border-radius: 3px;
  display: inline-block;
  padding: 0.8rem 1.6rem;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: $darker-tint;
  transition: background 0.3s;
  min-width: 304px;
  text-align: center;
  text-shadow: none;

  @media only screen and (min-width:630px) {
    padding: 0.8rem 3.2rem;
  }

  &:hover {
    background: $dark-tint;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: default;
      background: $darker-tint;
    }
  }

}

body.announcementBannerVisible {
  .main-copy {
    padding-top: 140px !important;
  }
}

.main-copy {
  padding: 100px 0.8rem 140px 0.8rem;
  position: relative;
  display: block;
  z-index: 5;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  color: white;

  -webkit-animation: fade-in-down 0.6s;
  animation: fade-in-down 0.6s;

  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;

  &.message-stripe {
    padding-top: 160px;
  }

  &.home {
    max-width: 600px;
    display: flex;
    min-height: 100vh;
    // padding-top: 100px;

    &.message-stripe {
      padding: 160px 0;
    }

    @media only screen and (min-width:720px) {
      max-width: 800px;
    }

    article {
      margin: auto;
    }

  }

}

article {
  font-size: 1.4rem;
  line-height: 1.8em;
  font-weight: 400;
  text-shadow: 0 0 20px rgba(59, 116, 55, 0.95);

  @media only screen and (min-width:720px) {
    font-size: 1.6rem;
    line-height: 2em;
  }

  blockquote {
    margin: 2em 0;
    padding: 0;
    padding: 0.8rem 1.6rem;
    font-size: 1.3rem;
    line-height: 1.8em;
    border-left: 5px solid $darker-tint;
    background: rgba(255,255,255,0.75);
    letter-spacing: 0.5px;
    text-shadow: none;
    color:$darker-tint;

    @media only screen and (min-width:720px) {
      font-size: 1.5rem;
      line-height: 1.8em;
    }

    cite {
      font-style: normal;
      text-transform: uppercase;
      font-size: 1.2rem;
    }

  }

  img {
    width:100%;
    margin: 1em 0;
  }

  a {
    text-decoration: none;
    color: white;
    border-bottom: 1px solid white;
    // border-color:$dark-tint;
    transition: border-color 0.3s;
    font-weight: 400;

    &:hover {
      border-color: rgba(255,255,255,0.5);
    }

  }

  ul {
    padding-left: 1.8rem;
    @media only screen and (min-width:720px) {
      padding-left: 3.2rem;
    }
  }

  .button {
    margin: 1em 0;
    border: 1px solid darken($darker-tint, 10%);

    &:hover {
      border-color:$darker-tint;
    }
  }

  hr {
    margin: 3rem 0;
  }

}

// Footer Start
.main-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 100px;
  padding-top: 100px;
  font-weight: 600;

  .wave {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 50px;
    background: transparent url(/assets/waves.svg) no-repeat top left;
    background-size: 100%;

    @media only screen and (min-width:720px) {
      height:100px;
      bottom: 40px;
    }

  }

   .stat-logo {
      width:32px;
      position: absolute;
      left:8px;
      top: 60px;
      margin-top:-19px;
      z-index: 50;
    }

  .foot-notes {
    height: 60px;
    line-height: 1.5em;
    text-align: center;
    color: $darker-tint;
    font-size: 1rem;
    text-transform: uppercase;
    //opacity: 0.8;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // background: rgba(72,51,28, 0.6);
    background: rgba(255,255,255,0.5);
    padding:10px 40px;

    @media only screen and (min-width:520px) {
      text-align: right;
      line-height: 40px;
      padding:0 40px;
    }

    @media only screen and (min-width:720px) {
      height: 40px;
    }

    a {
      text-decoration: none;
      color: $darker-tint;
      border-bottom: 1px solid white;
      border-color:$dark-tint;
      transition: border-color 0.3s;

      &:hover {
        border-color: $darker-tint;
      }

    }

    span.foot-txt {
      display:inline-block;

      @media only screen and (min-width:520px) {
        &:before {
          padding-right: 0.6rem;
          content: '\2022';
        }

        &:first-child {
          &:before {
            content:'';
          }
        }
      }

      padding-left: 0.6rem;
    }

  }

}


// Modal Start
.smoker {
  display:block;
  position:fixed;
  top:-100%;
  left:0;
  width:100%;
  height:100%;
  padding:0.4rem;
  z-index:900;
  overflow-y: auto;
  -webkit-overflow-scrolling:touch;
  background:rgba(0,0,0,0.5);

  @media only screen and (min-width:600px) {
    padding:1rem;
  }

  &:hover {
    cursor: pointer;
  }

  .modal {
    top:-100%;
    display: block;
    position:relative;
    z-index:999;
    padding:0.8rem;
    text-shadow: none;

    @media only screen and (min-width:600px) {
      padding:1.6rem;
    }

    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background:white; // #292827;
    color: #292827;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.3s, top 0.3s;

    &:hover {
      cursor: initial;
    }

    a {
      color: #292827;
      // border-color:rgba(0,0,0, 0.65);

      &:hover {
        // border-color: black;
      }
    }

    .closer {
      font-size:30px;
      position:absolute;
      top:5px;
      right:5px;
      text-align: center;
      line-height: 35px;

      // background:#333;
      color:$dark-tint;
      // opacity:0.75;
      border-radius: 50%;
      width: 35px;
      height: 35px;

      -webkit-transition:color 0.3s;
      transition:color 0.3s;

      &:hover {
        color:$darker-tint;
        cursor:pointer;
      }

      &:after {
        content:'\00d7';
      }

    }

    .button {
      width: 100%;
      min-width: 20px;
      color: white;
      margin-top: 0.8rem;
      margin-bottom: 1.6rem;
      outline: none;
    }

  }

  &.on {
    top: 0;

    .modal {
      opacity: 1;
      top: 0;
    }

  }

  &.off {
    top: -100%;

    .modal {
      opacity: 0;
    }
  }

}

.navOpen {
  overflow:hidden;

  .main-nav {
    display: block;
    z-index: 900;
    -ms-transform: translate(0,0); /* IE 9 */
    -webkit-transform: translate(0,0); /* Safari */
    transform: translate(0,0);
  }

  .navSmoker {
    display:block;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    padding:0.4rem;
    z-index:800;
    background: rgba(0,0,0,0.65);
    cursor: pointer;
    @media only screen and (min-width:$man-nav-break-point) {
      display:none;
    }
  }

}

@media only screen and (min-width:800px) {
  // sample mobile first media query
}

.logos {
  display: flex;

  .logo {
    width:150px;
    text-decoration: none;
    border: 0;

    &__cnhc {
      margin-left: 10px;

      img {
        display: block;
        width: 100%;
      }

    }

    &__stat {
      background: white url(/assets/stat_logo.svg) no-repeat center center;
      background-size: 93% auto;
      border-radius: 10px;
      display: block;
      border: 1px solid #999;
    }
  }
}

.announcement {
  position: fixed;
  bottom: 8px;
  left: 8px;
  z-index: 15;
  background: white;
  padding: 16px;
  width: 300px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border: 8px solid orange;
  display: none;

  &__icon {
    display: block;
    width: 24px;
    height:24px;
    position: absolute;
    top: 16px;
    left: 16px;

    path {
      fill: #333;
    }
  }

  &__close {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 4px;
    right: 0;
    font-size: 30px;
    text-align: center;
    line-height: 48px;
    color: $darker-tint;
    cursor: pointer;
  }

  &__text {
    margin-top: 12px;
  }

  h1 {
    font-size: 13px;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding-left: 28px;
  }

  p {
    margin: 0;
  }

  .button {
    min-width: 100%;
    text-decoration: none;
    color: white;
  }
}
